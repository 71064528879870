
import { defineComponent, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { TodosPayload } from "../types";
import notification from "./utils/notification.vue";

export default defineComponent({
  components: { notification },
  name: "CreateToDo",
  setup() {
    const store = useStore();
    const todoNotificationTitle = ref("Todo saved!");
    const todoNotificationSubTitle = ref("");
    const allowedToDosCount = 5;
    const todos = computed(() => store.getters["todos/todos"]);
    const todo = ref("" as string);
    const notification = ref(false as boolean);
    const allowedMaxTextCharacterCount = 100 as number;
    const showOptionalDetails = computed((): boolean =>
      Boolean(todo.value.length)
    );
    const textInputCount = computed((): number => todo.value.length);
    const isMaxInputCount = computed((): boolean =>
      Boolean(todo.value.length === allowedMaxTextCharacterCount)
    );
    watch(todo, () => {
      if (todo.value.length && notification.value) {
        notification.value = false;
      }
    });
    function clearInput() {
      todo.value = "";
    }
    function createToDo() {
      if (todos.value.length >= allowedToDosCount) {
        todoNotificationTitle.value = "Not Allowed";
        todoNotificationSubTitle.value = "You are allowed a max of 5 todos!";
        notification.value = true;
        return;
      } else if (
        todo.value &&
        todos.value.some(
          (t: { title: string }): boolean =>
            t.title.toLowerCase() === todo.value.toLowerCase()
        )
      ) {
        notification.value = true;
        todoNotificationTitle.value = "Not Allowed";
        todoNotificationSubTitle.value = "This item already exist!!";
        return;
      }
      store.commit("todos/CREATE_TODO", {
        title: todo.value,
        completed: false,
      } as TodosPayload);
      notification.value = true;
      todo.value = "";
      todoNotificationTitle.value = "Todo Saved!";
      todoNotificationSubTitle.value = "";
    }
    function closenotification(e: boolean): void {
      notification.value = e;
    }
    return {
      todo,
      textInputCount,
      showOptionalDetails,
      clearInput,
      isMaxInputCount,
      allowedMaxTextCharacterCount,
      createToDo,
      notification,
      closenotification,
      todoNotificationTitle,
      todoNotificationSubTitle,
    };
  },
});
