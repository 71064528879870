
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "ToDoStatusFilterCard",
  setup(props, context) {
    const currentFilter = ref("all");
    return {
      currentFilter,
      setStatus(s: string): void {
        currentFilter.value = s;
        context.emit("changeStat", s);
      },
    };
  },
});
