
import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";
import ToDoTaskItem from "@/components/toDoTaskItem.vue";
import Notification from "./utils/notification.vue";

export default defineComponent({
  name: "ToDoListCard",
  props: {
    status: {
      type: String,
      default: "all",
    },
  },
  components: {
    ToDoTaskItem,
    Notification,
  },
  setup(props) {
    const notification = ref(false as boolean);
    const todoNotificationTitle = ref("");
    const store = useStore();
    const todos = computed(() => store.getters["todos/todos"]);
    const fetchStatusTodo = computed(() => {
      if (props.status === "all") {
        return todos.value;
      } else if (props.status === "uncompleted") {
        return todos.value.filter(
          (todo: { title: string; completed: boolean }) => !todo.completed
        );
      } else {
        return todos.value.filter(
          (todo: { title: string; completed: boolean }) => todo.completed
        );
      }
    });
    function closenotification(e: boolean): void {
      notification.value = e;
    }
    function clearSelection() {
      store.commit("todos/CLEAR_TODOS", props.status);
      notification.value = true;
      todoNotificationTitle.value = `${props.status} tasks cleared`;
    }
    return {
      fetchStatusTodo,
      clearSelection,
      notification,
      todoNotificationTitle,
      closenotification,
    };
  },
});
