import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = ["maxlength"]
const _hoisted_3 = { class: "text-sm font-medium text-gray-900" }
const _hoisted_4 = {
  key: 0,
  class: "mt-1 text-sm text-gray-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notification = _resolveComponent("notification")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass([
        _ctx.showOptionalDetails ? 'block' : 'hidden',
        'absolute flex -top-6 w-full',
      ])
      }, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clearInput && _ctx.clearInput(...args))),
          role: "button",
          class: "mr-auto bg-red-200 rounded p-0.5 text-xs tracking-tighter"
        }, " Clear input "),
        _createElementVNode("small", {
          class: _normalizeClass([_ctx.isMaxInputCount ? 'text-red-600' : 'dark:text-white'])
        }, _toDisplayString(_ctx.textInputCount) + "/" + _toDisplayString(_ctx.allowedMaxTextCharacterCount), 3)
      ], 2),
      _createElementVNode("form", {
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.createToDo && _ctx.createToDo(...args)), ["prevent"])),
        class: _normalizeClass([
        _ctx.isMaxInputCount ? 'border-red-600' : 'border-transparent',
        'bg-white rounded-md py-4 pl-5 pr-2 flex border-2 dark:bg-darkVeryDarkDesaturatedBlue',
      ])
      }, [
        _withDirectives(_createElementVNode("textarea", {
          maxlength: _ctx.allowedMaxTextCharacterCount,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.todo = $event)),
          rows: "1",
          class: "focus:outline-none w-full pr-4 mr-auto dark:bg-darkVeryDarkDesaturatedBlue dark:text-white",
          placeholder: "Create a new todo..."
        }, null, 8, _hoisted_2), [
          [_vModelText, _ctx.todo]
        ]),
        _createElementVNode("button", {
          type: "submit",
          class: _normalizeClass([
          _ctx.showOptionalDetails ? 'block' : 'hidden',
          'bg-green-200 p-1 text-xs tracking-tighter w-20 shadow-md',
        ])
        }, " Create ", 2)
      ], 34)
    ]),
    _createVNode(_component_notification, {
      show: _ctx.notification,
      onClosenotification: _cache[3] || (_cache[3] = ($event: any) => (_ctx.closenotification($event)))
    }, {
      default: _withCtx(() => [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.todoNotificationTitle), 1),
        (_ctx.todoNotificationSubTitle)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.todoNotificationSubTitle), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}