
import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";
import CreateToDo from "@/components/createToDo.vue";
import ToDoStatusFilterCard from "@/components/toDoStatusFilterCard.vue";
import ToDoListCard from "@/components/toDoListCard.vue";

export default defineComponent({
  name: "Home",
  components: {
    CreateToDo,
    ToDoStatusFilterCard,
    ToDoListCard,
  },
  setup() {
    const statusCard = ref("all");
    const store = useStore();
    const isDarkThemeOn = computed(() => store.getters.stateOfDarkTheme);
    function toggle(): void {
      store.commit("TOGGLE_THEME");
    }
    function setStatusCard(e: string): void {
      statusCard.value = e;
    }
    return {
      setStatusCard,
      isDarkThemeOn,
      toggle,
      statusCard,
    };
  },
});
