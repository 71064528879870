import { withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rounded-md bg-white py-4 px-5 text-gray-500 flex dark:bg-darkVeryDarkDesaturatedBlue" }
const _hoisted_2 = { class: "mx-auto space-x-5 font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        role: "button",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.setStatus('all')), ["prevent"])),
        class: _normalizeClass({ 'text-blue-700': _ctx.currentFilter === 'all' })
      }, "All", 2),
      _createElementVNode("span", {
        role: "button",
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.setStatus('uncompleted')), ["prevent"])),
        class: _normalizeClass({ 'text-blue-700': _ctx.currentFilter === 'uncompleted' })
      }, "Uncompleted", 2),
      _createElementVNode("span", {
        role: "button",
        onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.setStatus('completed')), ["prevent"])),
        class: _normalizeClass({ 'text-blue-700': _ctx.currentFilter === 'completed' })
      }, "Completed", 2)
    ])
  ]))
}