
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    function close() {
      context.emit("closenotification", false);
    }
    return {
      close,
    };
  },
});
