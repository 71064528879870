
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import notification from "./utils/notification.vue";

export default defineComponent({
  components: { notification },
  props: {
    todo: {
      type: Object,
    },
  },
  setup() {
    const todoNotificationTitle = ref("Item Completed!");
    const notification = ref(false as boolean);
    const store = useStore();
    function markItem(index: number): void {
      store.commit("todos/MARK_TODO", index);
      notification.value = true;
    }
    function deleteToDo(index: number): void {
      store.dispatch("todos/deleteSingleTask", index);
      notification.value = true;
    }
    function closenotification(e: boolean): void {
      notification.value = e;
    }
    return {
      markItem,
      deleteToDo,
      notification,
      closenotification,
      todoNotificationTitle,
    };
  },
});
